import React from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class ContactUs extends React.Component {
    state = {
        formData: {
            name: '',
            email: '',
            message: '',
        },
        submitted: false,
    }

    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            const data = this.state.formData
            console.log(data.email, data)
            
            addToMailchimp(data.email, data)

        });
    }

    render() {
        const { formData, submitted } = this.state;
        return (
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
            >
                <TextValidator
                    label="Name"
                    onChange={this.handleChange}
                    name="name"
                    variant="filled"
                    value={formData.name}
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
                <br />
                <TextValidator
                    label="Email"
                    onChange={this.handleChange}
                    name="email"
                    variant="filled"
                    value={formData.email}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
                <br />
                <TextValidator
                    label="Message"
                    onChange={this.handleChange}
                    multiline
                    name="message"
                    variant="filled"
                    value={formData.message}
                    className="message-field"
                    validators={['required', 'required']}
                    errorMessages={['this field is required']}
                />
                <br />
                <Button
                    color="primary"
                    className="green-button"
                    variant="outlined"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Send Message')
                    }
                </Button>
            </ValidatorForm>
        );
    }
}